const date = new Date();
const day = date.getDate();
const month = date.getMonth() + 1;
const year = date.getFullYear();

let previousDay = day - 1;
let previousMonth = month;
let previousYear = year;

if (previousDay === 0) {
  previousMonth = month === 1 ? 12 : month - 1;
  previousYear = month === 1 ? year - 1 : year;

  const isFebruary = previousMonth === 2;
  const isLeapYear =
    (previousYear % 4 === 0 && previousYear % 100 !== 0) ||
    previousYear % 400 === 0;
  const lastDayOfPreviousMonth = isFebruary
    ? isLeapYear
      ? 29
      : 28
    : 30 + ((previousMonth + (previousMonth > 7 ? 1 : 0)) % 2);

  previousDay = lastDayOfPreviousMonth;
}

export const formattedCurrentDate = `${year}-${month
  .toString()
  .padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
export const formattedPreviousDate = `${previousYear}-${previousMonth
  .toString()
  .padStart(2, "0")}-${previousDay.toString().padStart(2, "0")}`;

var previousDate = new Date(formattedPreviousDate);
var currentDate = new Date(formattedCurrentDate);
var day1 = previousDate.getDate();
var month1 = previousDate.getMonth() + 1;
var year1 = previousDate.getFullYear();
var day2 = currentDate.getDate();
var month2 = currentDate.getMonth() + 1;
var year2 = currentDate.getFullYear();
export const formattedPreviousDate_f = `${day1 + "/" + month1 + "/" + year1}`;
export const formattedCurrentDate_f = `${day2 + "/" + month2 + "/" + year2}`;
