import React from "react";
import "./breadcrumbs.css";

export const BREADCRUMBS = (props) => {
  return (
    <div className="breadcrumbs-general">
      <a href="/">INICIO</a>
      {props.niveles.map((item) =>
        item.path === null ? (
          <>
            /<span>{item.label}</span>
          </>
        ) : (
          <>
            /<a href={item.path}>{item.label}</a>
          </>
        )
      )}
    </div>
  );
};
