//PAQUETERIAS
import React, { useState } from "react";
import { useDialog } from "react-st-modal";
import LoadingScreen from "react-loading-screen";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

//COMPONENTES
import {
  send_invoice,
  general_commissions_reports,
  general_public,
} from "../../../../services/invoices/invoice";

//ESTILOS
import "../../../estilo.css";
import "../../../modal.css";

//IMAGENES
import Spinner from "../../../../imgComponents/S.gif";
import { catchErrorModul } from "../../../../main_components/catchErr/catchError";
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";

const ReenvioCorreo = (props) => {
  const dialog = useDialog();
  const [isLoding, setIsLoding] = useState(false);
  const [email, setEmail] = React.useState("");
  const [ff, setff] = useState("");
  const [fi, setfi] = useState("");


  const handleChangeeEmail = (event) => {
    setEmail(event.target.value);
  };

  const send_email = async () => {
    try {
      setIsLoding(true);
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            await send_invoice(props?.id, props?.uuid, email);
            alert("Envío exitoso");
            if (props?.invoice === true) {
              console.log("entrando desde generar factura")
              dialog?.close();
              setIsLoding(false);
              window.location = "/GenerarFactura";
            } else {
              dialog?.close();
              setIsLoding(false);
            }


          } catch (err) {
            var dtErr = { email: email, uuid: props?.uuid, id: props?.id };
            alert(JSON.stringify(err?.response?.data));
            catchErrorModul(
              "Envíar Facturas",
              JSON.stringify(dtErr),
              JSON.stringify(err?.response)
            );
            setIsLoding(false);
          }
        }, 1000);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handle_report = async () => {
    var dt = { emails: email };
    try {
      setIsLoding(true);
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            await general_public(fi, ff, dt);
            alert(
              "El reporte será procesado.\nSe le enviará a su correo electrónico cuando esté disponible."
            );
            dialog.close();
            setIsLoding(false);
          } catch (err) {
            alert(JSON.stringify(err?.response?.data));
            catchErrorModul(
              "Envíar Facturas",
              JSON.stringify(dt),
              JSON.stringify(err?.response)
            );
            setIsLoding(false);
          }
        }, 1000);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handle_commissions_reports = async () => {
    var dt = { emails: email };
    try {
      setIsLoding(true);
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            await general_commissions_reports(fi, ff, dt);
            alert(
              "El reporte será procesado.\nSe le enviará a su correo electrónico cuando esté disponible."
            );
            dialog.close();
            setIsLoding(false);
          } catch (err) {
            alert(JSON.stringify(err?.response?.data));
            catchErrorModul(
              "Envíar Facturas",
              JSON.stringify(dt),
              JSON.stringify(err?.response)
            );
            setIsLoding(false);
          }
        }, 1000);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const hanlde_close = () => {
    if (props.invoice !== true) {
      dialog.close();
    } else {
      dialog.close(true);
      window.location = "/GenerarFactura";
    }
  }

  return (
    <div>
      {isLoding ? (
        <LoadingScreen
          className="LoadinScreen"
          loading={true}
          bgColor="#f1f1f1"
          spinnerColor="#9ee5f8"
          textColor="#676767"
          logoSrc={Spinner}
          text={"Enviando..."}
        />
      ) : (
        <div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <div
              className="contenerControllerReport"
              style={{
                display:
                  props.report === true
                    ? ""
                    : props.report === "commissions"
                      ? ""
                      : "none",
              }}
            >
              <div className="bp-1f">
                <input
                  type="text"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder="Fecha inicial"
                  onChange={(event) => setfi(event.target.value)}
                ></input>
              </div>
              <div className="bp-2f">
                <input
                  type="text"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder="Fecha Final"
                  onChange={(event) => setff(event.target.value)}
                ></input>
              </div>
            </div>

            <textarea
              placeholder="Correos electronicos"
              style={{ width: "92%", height: 80 }}
              onChange={handleChangeeEmail}
            ></textarea>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "98%"
              }}
            >
              <Button
                style={{ display: props.report === false ? "" : "none" }}
                sx={{ m: 2, width: "50%", background: "green" }}
                variant="contained"
                onClick={send_email}
              >
                Enviar
              </Button>

              <Button
                style={{ display: props.report === true ? "" : "none" }}
                sx={{ m: 2, width: "50%", background: "green" }}
                variant="contained"
                onClick={handle_report}
              >
                Enviar Reporte
              </Button>

              <Button
                style={{ display: props.report === "commissions" ? "" : "none" }}
                sx={{ m: 2, width: "50%", background: "green" }}
                variant="contained"
                onClick={handle_commissions_reports}
              >
                Generar Reporte
              </Button>

              <Button
                sx={{ m: 2, width: "50%", background: "red" }}
                variant="contained"

                onClick={() => hanlde_close()}
              >
                Cancelar
              </Button>

            </Box>

          </Box>
        </div>
      )}
    </div>
  );
};

export default ReenvioCorreo;
