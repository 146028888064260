import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import Button from "@mui/material/Button";
// import ReactJsAlert from "reactjs-alert";
import "./validador.css";
import envioPPDF from "../../imgComponents/pdf.png";
import envioXML from "../../imgComponents/xml.png";
import { catchErrorModul } from "../../main_components/catchErr/catchError";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import {
  get_expense_paymentaccounts,
  create_supplier_invoices,
} from "../../services/expenses/expenses";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";

export default function Validador() {
  const [cuentas, setCuentas] = useState([]);
  const [open, setopen] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [file2, setFile2] = useState(null);
  const [fileName2, setFileName2] = useState(null);

  const [numSer, setNumSer] = useState();
  const [folioType, setFolioType] = useState("");

  const handleChangee0 = (event) => {
    setNumSer(event.target.value);
    numType();
  };

  function bancodefault() {
    if (cuentas[0]?.default == true) {
      setFolioType(cuentas[0]?.payment_account);
    } else {
      setFolioType(cuentas[1]?.payment_account);
    }
  }

  function numType() {
    var indice2 = cuentas?.findIndex(
      (filio) => filio.payment_method === numSer
    );

    if (indice2 === -1) {
      try {
        setFolioType(cuentas[0]?.payment_account);
      } catch (err) {
        console.log(err);
      }
    } else {
      setFolioType(cuentas[indice2]?.payment_account);
    }
  }

  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };
  const fileTypes = ["PDF"];
  const fileTypes2 = ["XML"];

  const handleChange = (file) => {
    bancodefault();
    fileToBase64(file, (err, result) => {
      if (result) {
        setFile(result);
        setFileName(file);
      }
    });
  };

  const verificarPDFXML = () => {
    if (file == null) {
      alert("No has cargado ningún documento");
    } else {
      numType();
    }
  };

  const handleChange2 = (file) => {
    fileToBase64(file, (err, result) => {
      if (result) {
        setFile2(result);
        setFileName2(file);
      }
    });
  };

  const handleSubmit = async (event) => {
    event?.preventDefault();
    setopen(true);

    var dta = {
      files: [
        {
          type: "xml",
          content: file2?.replace("data:text/xml;base64,", ""),
        },
        {
          type: "pdf",
          content: file?.replace("data:application/pdf;base64,", ""),
        },
      ],
      paymentCard: folioType,
    };
    console.log(dta);

    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            await create_supplier_invoices(dta);
            setopen(false);
            alert("Exito, se envio correctamente los documentos");
            window.location = "/validador";
          } catch (err) {
            var arrError = err?.response?.data?.errorMessage?.split(".");
            catchErrorModul(
              "Envío de Documentos",
              JSON.stringify(dta),
              JSON.stringify(
                err?.response === undefined ? "Token expirado" : err?.response
              )
            );
      
            if (arrError[0] === "Error: This invoice already exist!") {
              alert("Error: El documento ya existe");
            } else if (arrError[0] === "Error: Invalid XML, check your files") {
              alert("Error: XML no válido, verifique sus archivos");
            } else if (arrError[0] === "Error: Missing files") {
              alert("Error: Perdida de archivos");
            } else if (
              arrError[0] === "Validation error: The invoice is not for this company"
            ) {
              alert("Error de validación: La factura no es para esta empresa.");
            } else if (
              arrError[0] ===
              "Validation error: You are temporarily blocked from sending documents"
            ) {
              alert(
                "Error de validación: Está bloqueado temporalmente para enviar documentos."
              );
            } else if (
              arrError[0] ===
              "Validation error: The invoice total is outside the allowed limit"
            ) {
              alert(
                "Error de validación: El total de la factura está fuera del límite permitido."
              );
            } else if (
              arrError[0] ===
              "Validation error: The invoice date is outside the allowed time"
            ) {
              alert(
                "Error de validación: La fecha de la factura está fuera del tiempo permitido."
              );
            } else if (
              arrError[0] ===
              "Validation error: You don't have permission to send documents yet"
            ) {
              alert("Todavía no tienes permiso para enviar documentos.");
            } else {
              alert("Error no controlado comuniquese con soporte.");
            }
      
            setopen(false);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };

  const handle_get_paymentaccounts = async () => {
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            var dta = await get_expense_paymentaccounts();
            setCuentas(dta.data);
          } catch (error) {}
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };

  useEffect(() => {
    handle_get_paymentaccounts();
  }, []);

  return (
    <div className="containerPDF-XML">
      <MODAL_TABLE open={true} modalT={open}></MODAL_TABLE>
      <BREADCRUMBS
        niveles={[
          {
            label: "ENVIO DE DOCUMENTOS",
            path: null,
          },
        ]}
      ></BREADCRUMBS>
      <div>
        <div className="xml-pdf-container">
          <div className="instrucciones-container ">
            <ul className="Instrucciones">
              <h4>Instrucciones:</h4>
              <li>
                <p>
                  {" "}
                  1. Arrastre o seleccione el PDF/XML a enviar en su apartado
                  correspondiente.{" "}
                </p>
              </li>
              <li>
                <p> 2. Selecciona la cuenta de pago. </p>
              </li>

              <li>
                3. Haga clic en el botón de enviar para subir su documento.
              </li>
            </ul>
          </div>

          <br></br>
          <br></br>

          <div className="pdf-container">
            <FileUploader
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              placeholder="123-45-678"
            >
              <div className="drangAndDrop">
                <img alt="" className="validadorDoc" src={envioPPDF}></img>

                <p>Arrastra o selecciona el PDF a envíar</p>
              </div>
            </FileUploader>
            <div className="upload-area">
              {fileName && <p className="filename">{fileName.name}</p>}
            </div>
          </div>
          <div className="xml-container">
            <FileUploader
              handleChange={handleChange2}
              name="file"
              types={fileTypes2}
              placeholder="123-45-678"
            >
              <div className="drangAndDrop">
                <img alt="" className="validadorDoc" src={envioXML}></img>

                <p>Arrastra o selecciona el XML a envíar</p>
              </div>
            </FileUploader>
            <div className="upload-area">
              {fileName2 && <p className="filename">{fileName2.name}</p>}
            </div>
          </div>

          <div className="pdf-container2">
            <p>Seleccione la cuenta de pago</p>

            <TextField
              id="Serie"
              select
              label="Cuenta de pago"
              value={numSer}
              sx={{ m: 1.5, width: "100%" }}
              onChange={handleChangee0}
              size="small"
              name="CFDIUse"
              onClick={verificarPDFXML}
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {cuentas?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.payment_method}
                </option>
              ))}
            </TextField>
          </div>

          <div className="boton-container">
            <Box>
              <Button
                sx={{ m: 2, width: "20ch" }}
                variant="contained"
                onClick={handleSubmit}
              >
                Enviar
              </Button>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}
