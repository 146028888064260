import React, { useEffect, useState } from 'react'

export const GENERATE_COLUMNS = (name, num) => {
    
  const columnNames = Array.from({ length: num }, (_, index) => `${name}_c${index + 1}`);
  
  const initialState = columnNames.reduce((state, columnName) => {
    const storedValue = localStorage.getItem(`${name}_${columnName}`);
    state[columnName] = 
    {
      value: storedValue === null ? true : storedValue === "true" ? true : false,
      set: (newValue) => {
        state[columnName].value = newValue;
        localStorage.setItem(`${name}_${columnName}`, newValue);
        setshowColumns({ ...state });
      }
    }
    return state;
  }, {});

  const [showcolumns, setshowColumns] = useState(initialState);
  
  useEffect(()=>{
    columnNames.forEach((columnName) => {
      localStorage.setItem(`productos_${columnName}`, showcolumns[columnName].value);
    });
  },[showcolumns]);

  return showcolumns
}

export const SHOWDATA = (columns, showcolumns, name) => {
    
  var n = 0;
  const datacolumns = columns?.map((item)=>{
    if(item?.id==="expander"){
      return null;
    }else{
      const columnName = `${name}_c${n + 1}`;
      n = n +1;
      return {
        view: item.show,
        setview: (newValue) => showcolumns[columnName].set(newValue),
        label: item.Header
      };
    }
  }).filter((item) => item !== null);

  return datacolumns
}