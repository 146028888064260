//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import { CustomDialog, Confirm } from "react-st-modal";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
//COMPONENTES
import { MaterialUISwitch } from "../../main_components/material_switch/switch";

//ESTILOS

import "../../components_cfdi/estilo.css";
import "../componentSectors/bonuses.css";
import "../../components_cfdi/modal.css";
import "./pointofsale.css";

//IMAGENES/ICONOS
import Delete from "../../imgComponents/borrar.png";
import Details from "../../imgComponents/lupa.png";
import CREATE from "../../imgComponents/create.png";
import Edit from "../../imgComponents/save.png";

import DetailsPointSale from "./DetailsPointsOfSale";
import CreatePointOfSale from "./CreatePointOfSale";
import EditPointsOfSale from "./EditPointsOfSale";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";

import NotificationSystem from "react-notification-system";
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { pagination } from "../../main_components/pagination/pagination";
import { SelectColumnFilter } from "../../components_cfdi/filters";
import TableContainer from "../../main_components/Table/TableContainer";
import { delete_points_of_sale } from "../../services/businesspartners/businesspartners";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import { COLUMS_SHOW } from "../../main_components/Table/ColumsShow";
const TablePointOfSale = () => {
  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [open, setopen] = useState(false);
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [filtersColumsView, setfiltersColumsView] = useState(true);


  const [viewcode, setviewcode] = useState(
    localStorage.getItem("viewcode") === null
      ? true
      : localStorage.getItem("viewcode") === "true"
      ? true
      : false
  );
  const [viewouter_code, setviewouter_code] = useState(
    localStorage.getItem("viewouter_code") === null
      ? false
      : localStorage.getItem("viewouter_code") === "true"
      ? true
      : false
  );
  const [viewspecial_number_folio, setviewspecial_number_folio] = useState(
    localStorage.getItem("viewspecial_number_folio") === null
      ? false
      : localStorage.getItem("viewspecial_number_folio") === "true"
      ? true
      : false
  );
  
  const [viewname, setviewname] = useState(
    localStorage.getItem("viewname") === null
      ? true
      : localStorage.getItem("viewname") === "true"
      ? true
      : false
  );

  const [viewbusinessPartner, setviewbusinessPartner] = useState(
    localStorage.getItem("viewbusinessPartner") === null
      ? true
      : localStorage.getItem("viewbusinessPartner") === "true"
      ? true
      : false
  );
  const [viewrfc, setviewrfc] = useState(
    localStorage.getItem("viewrfc") === null
      ? true
      : localStorage.getItem("viewrfc") === "true"
      ? true
      : false
  );
  const [viewchannel, setviewchannel] = useState(
    localStorage.getItem("viewchsetviewvisit_daysannel") === null
      ? true
      : localStorage.getItem("viewchannel") === "true"
      ? true
      : false
  );
  const [viewsubchannel, setviewsubchannel] = useState(
    localStorage.getItem("viewsubchannel") === null
      ? true
      : localStorage.getItem("viewsubchannel") === "true"
      ? true
      : false
  );
  const [viewroute, setviewroute] = useState(
    localStorage.getItem("viewroute") === null
      ? true
      : localStorage.getItem("viewroute") === "true"
      ? true
      : false
  );
  const [viewconditionPay, setviewconditionPay] = useState(
    localStorage.getItem("viewconditionPay") === null
      ? true
      : localStorage.getItem("viewconditionPay") === "true"
      ? true
      : false
  );
  const [viewgenerates_debt, setviewgenerates_debt] = useState(
    localStorage.getItem("viewgenerates_debt") === null
      ? true
      : localStorage.getItem("viewgenerates_debt") === "true"
      ? true
      : false
  );
  const [viewfrequency, setviewfrequency] = useState(
    localStorage.getItem("viewfrequency") === null
      ? true
      : localStorage.getItem("viewfrequency") === "true"
      ? true
      : false
  );
  const [viewdate_register, setviewdate_register] = useState(
    localStorage.getItem("viewdate_register") === null
      ? true
      : localStorage.getItem("viewdate_register") === "true"
      ? true
      : false
  );
  const [viewlat, setviewlat] = useState(
    localStorage.getItem("viewlat") === null
      ? false
      : localStorage.getItem("viewlat") === "true"
      ? true
      : false
  );
  const [viewlng, setviewlng] = useState(
    localStorage.getItem("viewlng") === null
      ? false
      : localStorage.getItem("viewlng") === "true"
      ? true
      : false
  );

  const [viewvisit_days, setviewvisit_days] = useState(
    localStorage.getItem("viewlng") === null
      ? false
      : localStorage.getItem("viewlng") === "true"
      ? true
      : false
  );
  const [viewprice_list, setviewprice_list] = useState(
    localStorage.getItem("viewlng") === null
      ? false
      : localStorage.getItem("viewlng") === "true"
      ? true
      : false
  );
  const [viewclassification, setviewclassification] = useState(
    localStorage.getItem("viewlng") === null
      ? false
      : localStorage.getItem("viewlng") === "true"
      ? true
      : false
  );
  const [viewnumber_oxxo_store, setviewnumber_oxxo_store] = useState(
    localStorage.getItem("viewlng") === null
      ? false
      : localStorage.getItem("viewlng") === "true"
      ? true
      : false
  );
  const [viewemail, setviewemail] = useState(
    localStorage.getItem("viewemail") === null
      ? false
      : localStorage.getItem("viewemail") === "true"
      ? true
      : false
  );
  const [viewphone, setviewphone] = useState(
    localStorage.getItem("viewlng") === null
      ? false
      : localStorage.getItem("viewlng") === "true"
      ? true
      : false
  );
  const [viewstreet, setviewstreet] = useState(
    localStorage.getItem("viewstreet") === null
      ? false
      : localStorage.getItem("viewstreet") === "true"
      ? true
      : false
  );
  const [viewoutdoor_number, setviewoutdoor_number] = useState(
    localStorage.getItem("viewoutdoor_number") === null
      ? false
      : localStorage.getItem("viewoutdoor_number") === "true"
      ? true
      : false
  );
  const [viewinside_number, setviewinside_number] = useState(
    localStorage.getItem("viewinside_number") === null
      ? false
      : localStorage.getItem("viewinside_number") === "true"
      ? true
      : false
  );
  const [viewneighborhood, setviewneighborhood] = useState(
    localStorage.getItem("viewneighborhood") === null
      ? false
      : localStorage.getItem("viewneighborhood") === "true"
      ? true
      : false
  );
  const [viewzip_code, setviewzip_code] = useState(
    localStorage.getItem("viewzip_code") === null
      ? false
      : localStorage.getItem("viewzip_code") === "true"
      ? true
      : false
  );
  const [viewcity, setviewcity] = useState(
    localStorage.getItem("viewcity") === null
      ? false
      : localStorage.getItem("viewcity") === "true"
      ? true
      : false
  );
  const [viewtown, setviewtown] = useState(
    localStorage.getItem("viewtown") === null
      ? false
      : localStorage.getItem("viewtown") === "true"
      ? true
      : false
  );
  const [viewstate, setviewstate] = useState(
    localStorage.getItem("viewstate") === null
      ? false
      : localStorage.getItem("viewstate") === "true"
      ? true
      : false
  );
  const [viewcountry, setviewcountry] = useState(
    localStorage.getItem("viewcountry") === null
      ? false
      : localStorage.getItem("viewcountry") === "true"
      ? true
      : false
  );
  const [viewreference, setviewreference] = useState(
    localStorage.getItem("viewreference") === null
      ? false
      : localStorage.getItem("viewreference") === "true"
      ? true
      : false
  );
  const [viewaddress_type, setviewaddress_type] = useState(
    localStorage.getItem("viewaddress_type") === null
      ? false
      : localStorage.getItem("viewaddress_type") === "true"
      ? true
      : false
  );

  const [viewunilever, setviewunilever] = useState(
    localStorage.getItem("viewunilever") === null
      ? false
      : localStorage.getItem("viewunilever") === "true"
      ? true
      : false
  );
  const [viewpropio, setviewpropio] = useState(
    localStorage.getItem("viewpropio") === null
      ? false
      : localStorage.getItem("viewpropio") === "true"
      ? true
      : false
  );
  useEffect(() => {
    localStorage.setItem("viewcode", viewcode);
    localStorage.setItem("viewname", viewname);
    localStorage.setItem("viewbusinessPartner", viewbusinessPartner);
    localStorage.setItem("viewrfc", viewrfc);
    localStorage.setItem("viewchannel", viewchannel);
    localStorage.setItem("viewsubchannel", viewsubchannel);
    localStorage.setItem("viewroute", viewroute);
    localStorage.setItem("viewfrequency", viewfrequency);
    localStorage.setItem("viewconditionPay", viewconditionPay);
    localStorage.setItem("viewgenerates_debt", viewgenerates_debt);
    localStorage.setItem("viewdate_register", viewdate_register);
    localStorage.setItem("viewlat", viewlat);
    localStorage.setItem("viewlng", viewlng);
    localStorage.setItem("viewvisit_days", viewvisit_days);
    localStorage.setItem("viewprice_list", viewprice_list);
    localStorage.setItem("viewclassification", viewclassification);
    localStorage.setItem("viewnumber_oxxo_store", viewnumber_oxxo_store);
    localStorage.setItem("viewemail", viewemail);
    localStorage.setItem("viewphone", viewphone);
    localStorage.setItem("viewstreet", viewstreet);
    localStorage.setItem("viewoutdoor_number", viewoutdoor_number);
    localStorage.setItem("viewinside_number", viewinside_number);
    localStorage.setItem("viewneighborhood", viewneighborhood);
    localStorage.setItem("viewzip_code", viewzip_code);
    localStorage.setItem("viewcity", viewcity);
    localStorage.setItem("viewtown", viewtown);
    localStorage.setItem("viewstate", viewstate);
    localStorage.setItem("viewcountry", viewcountry);
    localStorage.setItem("viewreference", viewreference);
    localStorage.setItem("viewaddress_type", viewaddress_type);
    localStorage.setItem("viewunilever", viewunilever);
    localStorage.setItem("viewpropio", viewpropio);
    localStorage.setItem("viewouter_code", viewouter_code);
    localStorage.setItem("viewspecial_number_folio", viewspecial_number_folio);



  }, [
    viewcode,
    viewname,
    viewbusinessPartner,
    viewrfc,
    viewchannel,
    viewsubchannel,
    viewroute,
    viewfrequency,
    viewconditionPay,
    viewgenerates_debt,
    viewdate_register,
    viewlat,
    viewlng,
    viewvisit_days,
    viewprice_list,
    viewclassification,
    viewnumber_oxxo_store,
    viewemail,
    viewphone,
    viewstreet,
    viewoutdoor_number,
    viewinside_number,
    viewneighborhood,
    viewzip_code,
    viewcity,
    viewtown,
    viewstate,
    viewcountry,
    viewreference,
    viewaddress_type,
    viewunilever,
    viewpropio,
    viewouter_code,
    viewspecial_number_folio
  ]);

  function alertas(msj, status) {
    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: "success",
        position: "br",
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: "error",
        position: "br",
        autoDismiss: 60,
      });
    }
  }
  const reconsultar = (n) => {
    if (n === true) {
      setmodalT(true);
    } else {
      setmodalT(false);
    }
  };

  const handleSubmit = async () => {
    setData([]);
    setopen(true);
    setmenssage("Buscando Puntos de Venta");
    setmodalGeneral(true);
    setmodalT(true);
    try {
      var rf_token = await refresh_token ();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            var prueba = await pagination(3, "pointofsales",null);
            setData(prueba === null ? [] : prueba);  
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
          }catch(err){
            console.error(err);
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
          }
        }, 1000);
      }else{}
    } catch (error) {}
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const updateMyData = (rowIndex, columnId, value) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const handlechecked = (event) => {
    setfiltersColumsView(event.target.checked);
  };

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div>
            <img
              id="crearsector"
              src={CREATE}
              onClick={async () => {
                const result = await CustomDialog(
                  <div>
                    <CreatePointOfSale
                      handleSubmit={handleSubmit}
                      reconsultar={reconsultar}
                      alertas={alertas}
                    />
                  </div>,
                  {
                    className: "modalpuntosventa",
                    title: "Crear Nuevo Punto de Venta",
                    showCloseIcon: true,
                    isCanClose: false,
                  }
                );
              }}
              title="Agregar punto de venta"
              alt=""
            ></img>
          </div>
        ),

        id: "Acciones",

        Cell: ({ row }) => {
          const DeleteBusines = async ()  => {
            setmodalT(true);
            setData([]);
            setopen(true);

            try {
              var rf_token = await refresh_token();
              if(rf_token===true){
                setTimeout(async() => {
                  try{
                    const dt = await delete_points_of_sale(row.original.id);
                    console.log(dt);
                    alertas("Punto de Venta Eliminado", true);
                    handleSubmit();
                  }catch(err){
                    console.log("error", err);
                    alertas("Error, vuelva a intentarlo", false);
                    handleSubmit();
                  }
                }, 1000);
              }else{}
            } catch (err) {
              console.log(err);
            }
          }

          return (
            <div>
              <img
                src={Delete}
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar el punto de venta?",
                    "Eliminar Punto de Venta",
                    "Si",
                    "No"
                  );
                  if (result) {
                    DeleteBusines();
                  }
                }}
                className=""
                title="Eliminar punto de venta"
                alt=""
              ></img>
              <img
                src={Details}
                onClick={async () => {
                  const result = await CustomDialog(
                    <div>
                      <DetailsPointSale row={row} />
                    </div>,
                    {
                      className: "modalpuntosventa",
                      title: "Detalles de punto de venta",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                }}
                className=""
                title="Detalles de punto de venta"
                alt=""
              ></img>
              <img
                src={Edit}
                onClick={async () => {
                  const result = await CustomDialog(
                    <div>
                      <EditPointsOfSale
                        handleSubmit={handleSubmit}
                        row={row}
                        alertas={alertas}
                        reconsultar={reconsultar}
                      />
                    </div>,
                    {
                      className: "modalpuntosventa",
                      title: "Editar punto de venta",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                }}
                className=""
                title="Editar punto de venta"
                alt=""
              ></img>
            </div>
          );
        },
      },
      {
        Header: "Código",
        accessor: "code",
        show: viewcode,
        id: "code",
      },
      {
        Header: "Código Unilever",
        accessor: "outer_code",
        show: viewouter_code,
        id: "outer_code",
      },
      {
        Header: "Folio Especial",
        accessor: "special_number_folio",
        show: viewspecial_number_folio,
        id: "special_number_folio",
      },


      
      {
        Header: "Nombre",
        accessor: "name",
        show: viewname,
        id: "name",
      },
      {
        Header: "Socio de Negocios",
        accessor: "businessPartner",
        show: viewbusinessPartner,
        id: "businessPartner",
      },
      {
        Header: "RFC",
        accessor: "rfc",
        show: viewrfc,
        id: "rfc",
      },
      {
        Filter: SelectColumnFilter,
        Header: "Canal",
        accessor: "channel",
        show: viewchannel,
        id: "channel",
      },
      {
        Filter: SelectColumnFilter,
        Header: "Subcanal",
        accessor: "subchannel",
        show: viewsubchannel,
        id: "subchannel",
      },
      {
        Filter: SelectColumnFilter,
        Header: "Ruta",
        accessor: "route",
        show: viewroute,
        id: "route",
      },
      {
        Filter: SelectColumnFilter,
        Header: "Pago",
        accessor: "conditionPay",
        show: viewconditionPay,
        id: "conditionPay",
      },

      {
        Header: "Deuda",
        accessor: (d) => {
          return d.generates_debt === true ? "SI" : "NO";
        },
        show: viewgenerates_debt,
        id: "generates_debt",
      },
      {
        Filter: SelectColumnFilter,
        Header: "Frecuencia",
        accessor: "frequency",
        show: viewfrequency,
        id: "frequency",
      },
      {
        Header: "Alta",
        accessor: "date_register",
        show: viewdate_register,
        id: "date_register",
      },

      {
        Header: "Días de visita",
        accessor: "visit_days",
        show: viewvisit_days,
        id: "visit_days",
      },

      {
        Header: "Lista de precios",
        accessor: "price_list",
        show: viewprice_list,
        id: "price_list",
      },

      {
        Header: "Clasificación",
        accessor: "classification",
        show: viewclassification,
        id: "classification",
      },

      {
        Header: "Número de Tienda",
        accessor: "number_oxxo_store",
        show: viewnumber_oxxo_store,
        id: "number_oxxo_store",
      },

      {
        Header: "Lat",
        accessor: "lat",
        show: viewlat,
        id: "lat",
      },
      {
        Header: "Lng",
        accessor: "lng",
        show: viewlng,
        id: "lng",
      },

      {
        Header: "E-Mail",
        accessor: "email",
        show: viewemail,
        id: "email",
      },

      {
        Header: "Número Telefónico",
        accessor: "phone",
        show: viewphone,
        id: "phone",
      },
      {
        Header: "Calle",
        accessor: (d) => {
          return d.address.street;
        },
        show: viewstreet,
        id: "street",
      },
      {
        Header: "Número Exterior",
        accessor: (d) => {
          return d.address.outdoor_number;
        },
        show: viewoutdoor_number,
        id: "outdoor_number",
      },
      {
        Header: "Número Interior",
        accessor: (d) => {
          return d.address.inside_number;
        },
        show: viewinside_number,
        id: "inside_number",
      },
      {
        Header: "Colonia",
        accessor: (d) => {
          return d.address.neighborhood;
        },
        show: viewneighborhood,
        id: "neighborhood",
      },
      {
        Header: "Código postal",
        accessor: (d) => {
          return d.address.zip_code;
        },
        show: viewzip_code,
        id: "zip_code",
      },
      {
        Header: "Ciudad",
        accessor: (d) => {
          return d.address.city;
        },
        show: viewcity,
        id: "city",
      },
      {
        Header: "Municipio",
        accessor: (d) => {
          return d.address.town;
        },
        show: viewtown,
        id: "town",
      },
      {
        Header: "Estado",
        accessor: (d) => {
          return d.address.state;
        },
        show: viewstate,
        id: "state",
      },
      {
        Header: "País",
        accessor: (d) => {
          return d.address.country;
        },
        show: viewcountry,
        id: "country",
      },
      {
        Header: "Referencia",
        accessor: (d) => {
          return d.address.reference;
        },
        show: viewreference,
        id: "reference",
      },
      {
        Header: "Tipo de dirección",
        accessor: (d) => {
          return d.address.address_type;
        },
        show: viewaddress_type,
        id: "address_type",
      },
      {
        Header: "Número de Congeladores Unilever",
        accessor: (d) => {
          var ndata=d.cabinets[0].unilever?.split(',');
          var numberdata=ndata.length;
          if(numberdata===1){

            if(ndata[0]===""){
              numberdata=0;
            }
          }

          return numberdata;
        },
        show: viewunilever,
        id: "numberUnilever",
      },
      {
        Header: "Congeladores Unilever",
        accessor: (d) => {
          return d.cabinets[0].unilever;
        },
        show: viewunilever,
        id: "Unilever",
      },
      {
        Header: "Número de Congeladores Propios",
        accessor: (d) => {
          var ndata=d?.cabinets[1].propio?.split(',');
          var numberdata=ndata?.length;
          if(numberdata===1){

            if(ndata[0]===""){
              numberdata=0;
            }
          }

          return numberdata;
        },
        show: viewunilever,
        id: "numberPropios",
      },

      {
        Header: "Congeladores Propios",
        accessor: (d) => {
          return d.cabinets[1].propio;
        },
        show: viewpropio,
        id: "Propio",
      },

      
    ],
    [
      viewcode,
      viewname,
      viewbusinessPartner,
      viewrfc,
      viewchannel,
      viewsubchannel,
      viewroute,
      viewfrequency,
      viewconditionPay,
      viewgenerates_debt,
      viewdate_register,
      viewlat,
      viewlng,
      viewvisit_days,
      viewprice_list,
      viewclassification,
      viewnumber_oxxo_store,
      viewemail,
      viewphone,
      viewstreet,
      viewoutdoor_number,
      viewinside_number,
      viewneighborhood,
      viewzip_code,
      viewcity,
      viewtown,
      viewstate,
      viewcountry,
      viewreference,
      viewaddress_type,
      viewunilever,
      viewpropio,
      viewouter_code,
      viewspecial_number_folio,
    ]
  );

  const datacolum = [
    {
      view: viewcode,
      setview: setviewcode,
      label: "Código"
    },
    {
      view: viewname,
      setview: setviewname,
      label: "Puntos de Venta"
    },
    {
      view: viewbusinessPartner,
      setview: setviewbusinessPartner,
      label: "Socio de Negocios"
    },
    {
      view: viewrfc,
      setview: setviewrfc,
      label: "RFC"
    },
    {
      view: viewchannel,
      setview: setviewchannel,
      label: "Canal"
    },
    {
      view: viewsubchannel,
      setview: setviewsubchannel,
      label: "SubCanal"
    },
    {
      view: viewroute,
      setview: setviewroute,
      label: "Ruta"
    },
    {
      view: viewconditionPay,
      setview: setviewconditionPay,
      label:"Pago"
    },
    {
      view: viewgenerates_debt,
      setview: setviewgenerates_debt,
      label: "Deuda"
    },
    {
      view: viewfrequency,
      setview: setviewfrequency,
      label:"Frecuencia"
    },
    {
      view: viewdate_register,
      setview: setviewdate_register,
      label:"Alta"
    },
    {
      view: viewlat,
      setview: setviewlat,
      label:"Latitud"
    },
    {
      view: viewlng,
      setview: setviewlng,
      label:"Longitud"
    },
    {
      view: viewvisit_days,
      setview: setviewvisit_days,
      label:"Días de Visita"
    },
    {
      view: viewprice_list,
      setview: setviewprice_list,
      label:"Lista de Precios"
    },
    {
      view: viewclassification,
      setview: setviewclassification,
      label:"Clasificación"
    },
    {
      view: viewnumber_oxxo_store,
      setview: setviewnumber_oxxo_store,
      label:"Número de Tienda"
    },
    {
      view: viewemail,
      setview: setviewemail,
      label: "E-Mail"
    },
    {
      view: viewphone,
      setview: setviewphone,
      label:"Teléfono"
    },
    {
      view: viewstreet,
      setview: setviewstreet,
      label:"Calle",
    },
    {
      view: viewoutdoor_number,
      setview: setviewoutdoor_number,
      label:"Número Exterior"
    },
    {
      view: viewinside_number,
      setview: setviewinside_number,
      label:"Número Interior"
    },
    {
      view: viewneighborhood,
      setview: setviewneighborhood,
      label:"Colonia"
    },
    {
      view: viewzip_code,
      setview: setviewzip_code,
      label:"Codigo Postal"
    },
    {
      view: viewcity,
      setview: setviewcity,
      label:"Ciudad"
    },
    {
      view: viewtown,
      setview: setviewtown,
      label:"Municipio"
    },
    {
      view: viewstate,
      setview: setviewstate,
      label:"Estado"
    },
    {
      view: viewcountry,
      setview: setviewcountry,
      label:"País"
    },
    {
      view: viewreference,
      setview: setviewreference,
      label: "Referencia"
    },
    {
      view: viewaddress_type,
      setview: setviewaddress_type,
      label:"Tipo de Dirección"
    },
    {
      view: viewunilever,
      setview: setviewunilever,
      label:"Congeladores Unilever"
    },
    {
      view: viewpropio,
      setview: setviewpropio,
      label:"Congeladores Propios"
    },
    {
      view: viewouter_code,
      setview: setviewouter_code,
      label:"Código Unilever"
    },
    {
      view: viewspecial_number_folio,
      setview: setviewspecial_number_folio,
      label:"Folio Especial"
    }
  ]


  return (
    <>
     
      <div className="TablePointOfSales">
        <div className="">
          <div style={{ width: "98%", height: "100%", paddingLeft: "20px" }}>
            <NotificationSystem
              ref={notificationSystemRef}
            ></NotificationSystem>
            <MODAL_TABLE
              open={true}
              message={menssage}
              modalGeneral={modalGeneral}
              modalT={modalT}
            ></MODAL_TABLE>
            <>
            <BREADCRUMBS niveles={
                [
                  {label: "CONSULTAR PUNTOS DE VENTA", path: null},
                  {label: "PUNTOS DE VENTA SIN SECTOR", path: "/consult_point_of_sale_without_sector"}, 
                ]
              }
          ></BREADCRUMBS>
              <COLUMS_SHOW
                filtersColumsView={filtersColumsView}
                datacolums={datacolum}
              ></COLUMS_SHOW>

              <div className="containerCutBox">
                <div className="expen-8 color-1">
                  <FormControlLabel
                    control={
                      <MaterialUISwitch
                        sx={{ m: 1 }}
                        onChange={handlechecked}
                        checked={filtersColumsView}
                      />
                    }
                    label={
                      filtersColumsView === true
                        ? "Activar Filtros"
                        : "Desactivar Filtros"
                    }
                  />
                </div>
              </div>
              <div className="tableposprincipal">
              <StickyTable>
                <TableContainer
                  paginado={"Puntos de Venta"}
                  consultas={"Puntos de Venta Consultados"}
                  nametable={"Puntos de Venta"}
                  exportar={true}
                  columns={columns}
                  data={data}
                  style={{ overflowx: 10 }}
                  updateMyData={updateMyData}
                  Gmodal={open}
                />
              </StickyTable>
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default TablePointOfSale;
